
import { AccountService } from "../services/account";
import { Transaction } from "plaid";
import { NetFlowVue } from "./NetFlowBaseVue";
import { Component, InjectReactive, Prop, Watch } from "vue-property-decorator";

import TransactionTable from '../components/TransactionTable.vue';
import { Options } from "vue-class-component";

@Options({
  components: {
    TransactionTable,
  },
})
export default class TransactionsView extends NetFlowVue {
  @Prop(String) accountId!: string;
  
  transactions: Transaction[] = [];
  pendingTransactions: Transaction[] = [];

  @InjectReactive() isInit!: boolean;
  @Watch("isInit", { immediate: true }) onIsInitChanged() {
    if (this.isInit) {
      this.loadTransactions();
    }
  }

  async loadTransactions() {
    const allTxns = await new AccountService().getAccountTransactions(this.getAccessToken(),this.accountId);

    this.transactions = allTxns.filter(txn=>!txn.pending);
    const pendingTxnThatHavePermTxn = this.transactions.filter(txn=>txn.pending_transaction_id).map(txn=>txn.pending_transaction_id );
    this.pendingTransactions = allTxns.filter(txn=>txn.pending && pendingTxnThatHavePermTxn.indexOf(txn.transaction_id)=== -1);
  }
}
