

import axios from "axios";
import { inject } from "vue";
import Swal from 'sweetalert2'
import { ProvideReactive, Watch } from "vue-property-decorator";
import { NetFlowVue } from "./views/NetFlowBaseVue";

export default class App extends NetFlowVue {
    private $gAuth: any;
    protected Vue3GoogleOauth: any = inject("Vue3GoogleOauth");

    $route: any;

    menuItems = [
        {name:"Accounts", link:"/accounts", class:"border-gray-800 hover:border-blue-600", icon:"fa-chart-area", color:"blue-600"},
        {name:"Transactions", link:"/transactions", class:"border-gray-800 hover:border-green-500", icon:"fa-dollar-sign", color:"green-500"},
        {name:"Plaid", link:"/plaid", class:" border-gray-800 hover:border-purple-500", icon:"fa-envelope", color:"purple-500"},
        {name:"Google Login", link:"/login", class:"border-gray-800 hover:border-red-500", icon:"fa-wallet", color:"red-500"},
    ]
    created() {
        axios.interceptors.response.use(
            res => res,
            err => {
                if(err?.response?.data) {
                    Swal.fire({
                        title: err.response.data.name,
                        text: err.response.data.error_message??err.response.data,
                        icon: 'error',
                        });
                } else {
                    Swal.fire({
                        title: "Generic error",
                        text: JSON.stringify(err?.message ? err.message: err),
                        icon: 'error',
                        });
                }
            }
        );
    }

    isCurrentSelection(item: MenuItem): boolean {
        return item.name !== 'Home'? this.$route.path.indexOf(item.link) === 0 : this.$route.path === item.link;
    }

    getItemClass(item: MenuItem) {
        return this.isCurrentSelection(item)? `border-${item.color}`: `border-gray-800 hover:border-${item.color}`;
    }

    getIconClass(item: MenuItem) {
        return this.isCurrentSelection(item)? `${item.icon} text-${item.color}`: item.icon;
    }
    
    @ProvideReactive() isInit = false;

    @Watch("Vue3GoogleOauth.isInit", { immediate: true }) onMatchChanged() {
        if (this.Vue3GoogleOauth.isInit) {
            NetFlowVue.Vue3GoogleOauth = this.Vue3GoogleOauth;
            NetFlowVue.$gAuth = this.$gAuth;
            this.isInit = true;
        }
    }
}

export class MenuItem {
    name?: string;
    link?: string;
    class?: string;
    color?: string;
    icon?: string;
}
