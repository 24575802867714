
  import { Transaction } from "plaid";
  import { Prop} from "vue-property-decorator";
  import { SortUtils } from "../utils/sort-utils";
  import { NetflowTransaction } from "../../../shared/models/netflow-transaction";
  import { NetFlowVue } from "../views/NetFlowBaseVue";

  export default class TransactionTable extends NetFlowVue {
    @Prop({type: [Array]}) readonly transactions!: Transaction[];
    sortUtils = new SortUtils();
    private sortColumn = "date";
    private sortAsc = false;

    sortClicked(collection: NetflowTransaction[], columName: string) {
      if (columName === this.sortColumn) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortColumn = columName;
      }
      this.sortUtils.sortTransactions(collection, this.sortColumn, this.sortAsc);
    }
  }
